import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { DetailsInvestorSurvey, linkResolver } from '@pwc-new-ventures/ui';
import Layout from '../layouts';

const postPrefix = 'resources';

export default function() { 
  const { contentfulDetailsPage } = useStaticQuery(graphql`
    {
      contentfulDetailsPage(slug: {eq: "features"}) {
        metaTitle
        metaDescription
        image {
          ...ImageSharpMedium
        }
        heroCtaLink {
          slug
          internal {
            type
          }
        }
        heroCtaText
        heroCtaCopy
        heroDescription
        heroHeadline
        heroImage {
          ...ImageSharpLarge
        }
        navigatorsHeadline
        navigatorsDescription {
          json
        }
        navigators {
          ... on ContentfulNavigator {
            headline
            blurb {
              json
            }
            ctaText
            ctaLink {
              ...NavigatorCtaLink
            }
            image {
              ...ImageSharpMedium
            }
          }
        }
        pricingHeadline
        pricingDescription {
          json
        }
        pricingCtaText
        pricingCtaCopy
        pricingCtaLink {
          slug
          internal {
            type
          }
        }
        priceTiers {
          id
          priceFrequency
          price
          customerType
          customerTypeDescription
          customerTypeIcon {
            ...ImageSharpOriginal
          }
          startingAt
        }
        clientsHeadline
        clientLogos {
          companyLogo {
            ...ImageSharpOriginal
          }
        }
        clientsDescription {
          json
        }
        clientsCtaText
        clientsCtaLink {
          slug
          internal {
            type
          }
        }
        closingNavigator {
          headline
          ctaText
          ctaLink {
            ...NavigatorCtaLink
          }
        }
      }
    }
  `);

  const doc = {
    meta: {
      title: contentfulDetailsPage.metaTitle,
      description: contentfulDetailsPage.metaDescription,
      image:
        contentfulDetailsPage.image &&
        contentfulDetailsPage.image.localFile.childImageSharp.fluid.src,
    },
    hero: {
      headline: contentfulDetailsPage.heroHeadline,
      body: contentfulDetailsPage.heroDescription,
      buttonHeadline: contentfulDetailsPage.heroCtaCopy,
      buttonText: contentfulDetailsPage.heroCtaText,
      buttonUrl:
        contentfulDetailsPage.heroCtaLink &&
        linkResolver({
          type: contentfulDetailsPage.heroCtaLink.internal.type,
          slug: contentfulDetailsPage.heroCtaLink.slug,
          postPrefix,
        }),
      image: contentfulDetailsPage.heroImage,
    },
    highlights: {
      header: {
        headline: contentfulDetailsPage.navigatorsHeadline,
        body: contentfulDetailsPage.navigatorsDescription,
      },
      cards: contentfulDetailsPage.navigators.map((navigator) => ({
        headline: navigator.headline,
        body: navigator.blurb,
        buttonText: navigator.ctaText,
        buttonType: navigator.ctaLink && navigator.ctaLink[0].internal.type,
        buttonUrl:
          navigator.ctaLink &&
          linkResolver({
            type: navigator.ctaLink[0].internal.type,
            slug: navigator.ctaLink[0].redirectUrl || navigator.ctaLink[0].slug,
            postPrefix,
          }),
        image: navigator.image,
        video: navigator.video,
      })),
    },
    pricing: {
      headline: contentfulDetailsPage.pricingHeadline,
      description: contentfulDetailsPage.pricingDescription,
      tiers: contentfulDetailsPage.priceTiers.map((tier) => ({
        id: tier.id,
        customer: tier.customerType,
        description: tier.customerTypeDescription,
        starting: tier.startingAt,
        price: tier.price,
        frequency: tier.priceFrequency,
        image: tier.customerTypeIcon.localFile.publicURL,
      })),
      buttonHeadline: contentfulDetailsPage.pricingCtaCopy,
      buttonText: contentfulDetailsPage.pricingCtaText,
      buttonUrl:
        contentfulDetailsPage.pricingCtaLink &&
        linkResolver({
          type: contentfulDetailsPage.pricingCtaLink.internal.type,
          slug: contentfulDetailsPage.pricingCtaLink.slug,
          postPrefix,
        }),
    },
    logos: {
      headline: contentfulDetailsPage.clientsHeadline,
      body: contentfulDetailsPage.clientsDescription,
      images:
        contentfulDetailsPage.clientLogos &&
        contentfulDetailsPage.clientLogos.map(({ companyLogo }) => companyLogo.localFile.publicURL),
      buttonText: contentfulDetailsPage.clientsCtaText,
      buttonUrl:
        contentfulDetailsPage.clientsCtaLink &&
        linkResolver({
          type: contentfulDetailsPage.clientsCtaLink.internal.type,
          slug: contentfulDetailsPage.clientsCtaLink.slug,
          postPrefix,
        }),
      background: 'dark-1',
    },
    cta: contentfulDetailsPage.closingNavigator && {
      headline: contentfulDetailsPage.closingNavigator.headline,
      buttonText: contentfulDetailsPage.closingNavigator.ctaText,
      buttonType:
        contentfulDetailsPage.closingNavigator.ctaLink &&
        contentfulDetailsPage.closingNavigator.ctaLink[0].internal.type,
      buttonUrl:
        contentfulDetailsPage.closingNavigator.ctaLink &&
        linkResolver({
          type: contentfulDetailsPage.closingNavigator.ctaLink[0].internal.type,
          slug:
            contentfulDetailsPage.closingNavigator.ctaLink[0].redirectUrl ||
            contentfulDetailsPage.closingNavigator.ctaLink[0].slug,
          postPrefix,
        }),
      background: 'light-7',
      primary: true,
    },
  };

  return (
    <Layout title={doc.meta.title} description={doc.meta.description} image={doc.meta.image}>
      <DetailsInvestorSurvey doc={doc} Link={Link} />
    </Layout>
  );
}
